/* You can add global styles to this file, and also import other style files */
/*@import url('/Content/bootstrap.min.css');*/


.text-decoration-none {
    text-decoration: none !important;
}

.link-body-emphasis {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.breadcrumb-container {
    align-self: flex-start;
    width: 100%;
    margin-left: 0 !important;
}

.breadcrumb-item {
    font-weight: 600;
    color: #6c757d;
    text-decoration: none;
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
}

.breadcrumb-item.active {
    font-weight: 400;
    color: #6c757d;
}

.form-group {
    margin-bottom: 10px;
}

.checkbox {
    margin-bottom: 10px;

    label {
        font-weight: 400;
        line-height: 1em;
    }
}

input[type="checkbox"] {
    margin-right: 10px;
    vertical-align: bottom;
}
